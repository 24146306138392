<template>
  <div class="content">
    <div class="content__title content__title--party">
      <h1 class="title title--big title--theme">Информационное обеспечение деятельности</h1>
      <router-link v-if="userRole === 'admin'" :to="{ name: 'information-stat' }" class="link" type="button">
        <img src="@/assets/img/sidebar/stat.svg" alt="Stat icon">
        <span>Статистика</span>
      </router-link>
    </div>
    <div class="content__title">
      <h2 class="title title--theme">Региональная организация: {{ regionOrgName }}</h2>
    </div>
    <div class="content__tabs">
      <div class="tabs-list">
        <ul class="tabs-list__body">
          <li class="tabs-list__item">
            <router-link :to="{ name: 'information-official' }" active-class="tabs-list__link--active"
                         class="tabs-list__link">Официальные группы
            </router-link>
          </li>
          <li class="tabs-list__item">
            <router-link :to="{ name: 'information-federal' }" active-class="tabs-list__link--active"
                         class="tabs-list__link">Федеральные СМИ
            </router-link>
          </li>
          <li class="tabs-list__item">
            <router-link :to="{ name: 'information-regional' }" active-class="tabs-list__link--active"
                         class="tabs-list__link">Региональные СМИ
            </router-link>
          </li>
          <li class="tabs-list__item">
            <router-link :to="{ name: 'information-education' }" active-class="tabs-list__link--active"
                         class="tabs-list__link">Фед. и рег. органы исп. власти, уч. заведения
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <router-view class="content__body"/>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'InformationLayout',
  computed: {
    regionOrgName() {
      return this.$store.state.organizationName
    },
    userRole() {
      return this.$store.state.userRole
    }
  },
}
</script>
<style lang="sass">
.fade-enter-active, .fade-leave-active
  transition: opacity 0.5s

.fade-enter, .fade-leave-to
  opacity: 0
</style>
